import { Link } from 'gatsby'
import React from 'react'
import "../../../styles/AdminPage.scss";
import Navbar from '../../../sections/Navbar/Navbar';
import Footer from '../../../sections/Footer';

function AdminPage() {
    return (
        <>
            <Navbar />
            <div className="AdminHome">
                <span>
                    The <b>#Coachello App</b> empowers emerging leaders to find their growth opportunities and work on them through 1:1 coaching. Think of it as 100% personalised people development embedded into the workplace.
                </span>
                <ul className="link-list">
                    <b>
                        <li><Link to="hr-admin-dashboard">• HR Admin Dashboard</Link></li>
                        <li><Link to="install-coachello-app">• Install - Coachello App</Link></li>
                    </b>
                </ul>
            </div>
            <Footer />
        </>
    )
}

export default AdminPage